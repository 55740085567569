import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '../assets/icon/icon.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const config = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#4DBB7F',
        secondary: '#3F9EE7',
        background: '#050505'
      },
      dark: {
        primary: '#4DBB7F',
        secondary: '#3F9EE7',
        background: '#050505'
      }
    },
    options: {
      customProperties: true
    }
  },
  icons: {
    iconfont: 'mdi' || 'fa'
  }
}

export default new Vuetify(config)
